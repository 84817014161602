@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&family=PT+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=PT+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);

@font-face {
  font-family: 'Geomanist-book';
  src: local('Geomanist-book'), url(../assets/fonts/geomanist-book-webfont.woff) format("woff");
  font-display: fallback;
}

@font-face {
  font-family: 'Geomanist-medium';
  src: local('Geomanist-medium'), url(../assets/fonts/geomanist-medium-webfont.woff) format("woff");
  font-display: fallback;
}

@font-face {
  font-family: 'Geomanist-regular';
  src: local('Geomanist-regular'), url(../assets/fonts/geomanist-regular-webfont.woff) format("woff");
  font-display: fallback;
}

@font-face {
  font-family: 'Geomanist-bold';
  src: local('Geomanist-bold'), url(../assets/fonts/geomanist-bold-webfont.woff) format("woff");
  font-display: fallback;
}

@font-face {
  font-family: 'Geomanist-light';
  src: local('Geomanist-light'), url(../assets/fonts/geomanist-light-webfont.woff) format("woff");
  font-display: fallback;
}

@font-face {
  font-family: 'Oswald-regular';
  src: local('Oswald-regular'), url(../assets/fonts/oswald-regular.ttf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: 'Juniperbay-regular';
  src: local('Juniperbay-regular'), url(../assets/fonts/juniperbay-regular-webfont.woff) format("woff");
  font-display: fallback;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


a {
  text-decoration: none;
  color: #000;
  display: inline-block;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: 'Poppins', 'PT Sans Narrow', sans-serif;
  font-weight: normal;
  color: #333;
  font-size: 18px;
}

body.modelPopupOpen{
  overflow: visible !important;
}

#root {
  scrollbar-gutter: stable;
  overflow: inherit;
}

.bcFloat { 
  left: inherit !important;
  top: inherit !important;
  right: 10px;
  bottom: 100px;
  z-index: 100 !important;
  position: fixed !important;
}

@media only screen and (min-width: 768px) {
  .bcFloat { 
    bottom: 90px;
  }
  
}
